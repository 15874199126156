.nav-link-text-coloured {
    color: #4F9AF9;
    font-weight: 600;
}
.selected-component-menu {
    width: max-content !important;
    height: max-content !important;
    /* padding: 0 !important; */
    padding-right: 25px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.nav-link-text-gray:hover {
    color: #4F9AF9;
}
.icon-transactions > img {
    width: 30px !important;
}
.icon-transactions + h3 {
    font-weight: 600;
}
.last-withdraw-box {
    border-left: 1px solid rgba(197, 195, 195, 0.507);
    padding-left: 15px;
}
.available-withdraw-box {
    border-left: 1px solid rgba(197, 195, 195, 0.507);
    padding-left: 15px;
}
.transactions-main-title{
    font-size: 1.8rem;
    font-weight: 600;
}
.withdraw-form {
    /* padding: 15px; */
}
.btn-submit {
  background: rgb(122,117,250);
  background: linear-gradient(90deg, rgba(122,117,250,1) 4%, rgba(105,128,249,1) 7%, rgba(89,138,250,1) 32%, rgba(79,143,249,1) 77%);
border: 0;

}

.amount-withdraw-fund-last-step {
    border: 0 !important;
}
.amount-withdraw-fund-last-step-container {
    border: 1px solid #D8E2EF;
    border-radius: 8px;
    padding: 10px;
}
.img-container {
    padding: 15px;
    border-radius: 50px;
    background: #ECE5FF;
    width: 130px;
    margin: auto;
    height: 70px;
}
.dashboard-selected:hover {
background: #ebedef !important;
}
