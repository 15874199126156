@media (max-width: 990px) {
  .last-withdraw-box {
    border-left: 0;
    padding-left: 0;
    margin-top: 30px;
    margin-bottom: 30px;
}
.available-withdraw-box {
    border-left: 0;
    padding-left: 0;
}
}
